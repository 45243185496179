<template>
  <div class="content d-flex flex-column flex-column-fluid">
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom gutter-b no-print">
          <!--begin::Body-->
          <div class="card-body p-0">
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <h3 class="mb-10 font-weight-bold text-dark">
                  Warehouse Outbound Transaction
                </h3>

                <b-form-group label-cols-sm="3" id="input-group-1" label="Reference Number" label-for="input-3">
                  <b-form-input
                    v-model="referenceNo"
                    id="input-3"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    name="referenceNo"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-sm="3" id="input-group-1" label="Belong to Client" label-for="input-1">
                  <b-form-select
                    id="input-3"
                    v-model="belongTo"
                    :options="clientOptions"
                    class="form-control form-control-lg form-control-solid"
                    @change="clientSelected"
                  ></b-form-select>
                </b-form-group>

                <b-form-group label-cols-sm="3" id="input-group-1" label="Company" label-for="input-3">
                  <b-form-input
                    v-model="company"
                    id="input-3"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    name="referenceNo"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-sm="3" id="input-group-1" label="Address" label-for="input-3">
                  <b-form-input
                    v-model="address"
                    id="input-3"
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    name="referenceNo"
                  ></b-form-input>
                </b-form-group>

                <b-button size="md" variant="white" class="mb-2" @click="addTransaction">
                  <b-icon icon="plus-circle" aria-label="Add"></b-icon>
                </b-button>

                <div class="row align-items-baseline" v-for="(move, index) in movement" :key="index">
                  <div class="col-6">
                    <b-form-group label-cols-sm="3" id="input-group-1" label="Product" label-for="input-3">
                      <v-select
                        v-model="move.product"
                        label="name"
                        :options="products"
                        :reduce="product => product.id"
                      ></v-select>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group label-cols-sm="4" id="input-group-1" label="Quantity" label-for="input-3">
                      <b-form-input
                        v-model="move.qty"
                        id="input-3"
                        type="number"
                        class="form-control form-control-sm"
                        name="description"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-1">
                    <b-button size="sm" variant="danger" class="mb-2" @click="removeRow(index)">
                      <b-icon icon="trash" aria-label="Delete"></b-icon>
                    </b-button>
                  </div>
                  <div class="col-1">
                    <i class="flaticon2-check-mark text-success" v-if="move.status"></i>
                  </div>
                </div>

                <div class="d-flex justify-content-between border-top pt-10">
                  <button
                    class="btn-primary btn font-weight-bolder text-uppercase px-6"
                    :disabled="loading"
                    @click="submit"
                  >
                    <b-spinner small v-show="loading"></b-spinner>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom overflow-hidden" v-if="packingData">
      <div class="card-body p-0">
        <!-- begin: Invoice-->
        <!-- begin: Invoice header-->
        <div class="row justify-content-center py-8 px-8 py-md-27 px-md-0">
          <div class="col-md-9">
            <div class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
              <h1 class="display-4 font-weight-boldest mb-10">PACKING LIST</h1>
              <div class="d-flex flex-column align-items-md-end px-0">
                <!--begin::Logo-->
                <a href="#" class="mb-5">
                  <img src="assets/media/logos/logo-dark.png" alt="" />
                </a>
                <!--end::Logo-->
                <span class="d-flex flex-column align-items-md-end opacity-70">
                  <span>LJC Express Pte Ltd</span>
                  <span>21 Moonstone Ln, #06-02 Poh Leng Building, Singapore 328462</span>
                </span>
              </div>
            </div>
            <div class="border-bottom w-100"></div>
            <div class="d-flex justify-content-between pt-6">
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">DATE</span>
                <span class="opacity-70">{{ new Date().toLocaleDateString() }}</span>
              </div>
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">Client</span>
                <span class="opacity-70">{{ packingData.client }}</span>
              </div>
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">PACKING LIST NO.</span>
                <span class="opacity-70">{{ packingData.id }}</span>
              </div>
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">SEND TO.</span>
                <span class="opacity-70">{{ packingData.company }} <br />{{ packingData.address }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- end: Invoice header-->
        <!-- begin: Invoice body-->
        <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
          <div class="col-md-9">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="pl-0 font-weight-bold text-muted text-uppercase">Product</th>
                    <th class="text-right font-weight-bold text-muted text-uppercase">SKU</th>
                    <th class="text-right font-weight-bold text-muted text-uppercase">Description</th>
                    <th class="text-right pr-0 font-weight-bold text-muted text-uppercase">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="font-weight-boldest" v-for="product in packingData.products" :key="product.id">
                    <td class="border-top-0 pl-0 py-4">{{ product.product.name }}</td>
                    <td class="border-top-0 text-right py-4">{{ product.product.sku }}</td>
                    <td class="border-top-0 text-right py-4">{{ product.product.description }}</td>
                    <td class="border-top-0 pr-0 py-4 text-right">{{ product.qty }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end: Invoice body-->
        <!-- begin: Invoice action-->
        <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0 no-print">
          <div class="col-md-9">
            <div class="d-flex justify-content-between">
              <button type="button" class="btn btn-primary font-weight-bold" onclick="window.print();">
                Print Invoice
              </button>
            </div>
          </div>
        </div>
        <!-- end: Invoice action-->
        <!-- end: Invoice-->
      </div>
    </div>
  </div>
</template>

<script>
import { listClient } from "@/api/job.api";
import { getAllProductByClient, outbound } from "@/api/warehouse.api";
// import { useNotify } from "@/utils/notify";

import { ref, reactive, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "outboundWarehouse",
  components: { vSelect },
  setup() {
    // const { notify } = useNotify(context);

    const referenceNo = ref("");
    const belongTo = ref("");
    const company = ref("");
    const address = ref("");
    const clientOptions = ref([]);

    const loading = ref(false);
    const movement = reactive([]);

    const products = ref([]);

    const packingData = ref(null);
    onMounted(async () => {
      listClient().then(response => {
        const clients = response.data;

        clients.forEach(client => {
          clientOptions.value.push({ text: client.name, value: client.id });
        });
      });
    });

    const clientSelected = async client => {
      const response = await getAllProductByClient(client);
      products.value = response.data;
    };

    const addTransaction = function() {
      movement.push({ product: "", qty: 0, status: null });
    };

    const removeRow = function(index) {
      movement.splice(index, 1);
    };

    const submit = async function() {
      loading.value = true;

      let products = [];
      movement.forEach(move => {
        products.push({
          product: move.product,
          qty: move.qty
        });
      });

      const payload = {
        belong_to: belongTo.value,
        company: company.value,
        address: address.value,
        reference_no: referenceNo.value,
        products: products
      };

      try {
        const { status, data } = await outbound(payload);
        console.log(data);

        if (status === 200) {
          packingData.value = data;
        }
      } catch (e) {
        console.error(e);
      }

      loading.value = false;
    };

    return {
      referenceNo,
      belongTo,
      company,
      address,
      clientOptions,
      loading,
      addTransaction,
      removeRow,
      products,
      movement,

      clientSelected,
      submit,

      packingData
    };
  }
};
</script>

<style>
@media print {
  .no-print {
    display: none;
  }
}
</style>
